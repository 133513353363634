<template>
    <div>
      <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  تعديل  سعر الصرف">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="employe"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> من</label>
                <v-select
                  v-model="from_currency_id"
                  :options="optionCurrency"
                  :reduce="(val) => val.value"
                  :selectable="option => to_currency_id !=option.value"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="employe"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الى</label>
                <v-select
                  v-model="to_currency_id"
                  :options="optionCurrency"
                  :selectable="option => from_currency_id !=option.value"
                 
                  :reduce="(val) => val.value"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="from_currency_id"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> التقييم</label>
                <b-form-input v-model="rate" type="number" id="input-default" />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="4">
                <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                  <label>من تاريخ </label>
                  <flat-pickr v-model="from_date"    class="form-control"
                      placeholder="حدد تاريخ " />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="3" xl="4">
                <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                  <label>الى تاريخ </label>
                  <flat-pickr v-model="to_date"    class="form-control"
                      placeholder="حدد تاريخ " />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="editExchangeRate">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل السعر الصرف</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
      <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
    </div>
  </template>
  
  <script>
  import "vue-good-table/dist/vue-good-table.css";
  import { VueGoodTable } from "vue-good-table";
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { required, max, is_earlier } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  
  import {
    BRow,
    BCol,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
  } from "bootstrap-vue";
  import Cleave from "vue-cleave-component";
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us";
  
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  import {
    BFormDatepicker,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BForm,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  export default {
  
  
    components: {
      flatPickr,
      ToastificationContent,
      required,
      BDropdown,
      BDropdownItem,
      BDropdownItemButton,
      BFormInvalidFeedback,
      VueGoodTable,
      BForm,
      ValidationObserver,
      ValidationProvider,
  
      BCardCode,
      BButton,
      BFormInput,
      BFormFile,
      BFormGroup,
      BInputGroupPrepend,
      BInputGroup,
      BFormDatepicker,
      Cleave,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BRow,
  
      BCol,
    },
  
    data() {
      return {
        to_date: "",
      from_date:'',
      rate:'',
      from_currency_id: "",
      id: "",
      searchTerm: "",
      optionerewardType: [
    ],
    to_currency_id:'',
    optionitem:[],
    optionCurrency: [],
      };
    },
  
    created() {
   this.getExchangeRate()
  
      this.getoptionCurrency();
      this.getItem()
  
    },
   
  
    methods: {
      getExchangeRate() {
    
    this.$http.get(`/api/v1/exchange-rate/${this.$route.params.id}`).then((res) => {
      //console.log(res.data.data);
      this.from_currency_id = res.data.data.from_currency_id.id;
      this.to_currency_id=res.data.data.to_currency_id.id;
      this.from_date=res.data.data.from_date;
      this.to_date=res.data.data.to_date;
      this.rate=res.data.data.rate;

    });
  },
      getoptionCurrency() {
        this.optionCurrency=[]
      this.$http.get("/api/v1/currency").then((res) => {
        //console.log(res);
       
        res.data.forEach((el) => {
          this.optionCurrency.push({ value: el.id, label: `${el.name} `
 });
        });
        //console.log(this.optionCurrency);
      });
      },
      getItem() {
      this.optionitem=[]
      this.$http.get("/api/v1/item").then((res) => {
        //console.log('itemres',res);
        this.optionitem=res.data.data;
       
        //console.log(this.optionitem);
      });
    },
    
    editExchangeRate() {
        return new Promise((resolve, reject) => {
          this.$refs.simple
            .validate()
            .then((success) => {
              if (success) {
                let data={
      from_currency_id: this.from_currency_id,
      to_currency_id: this.to_currency_id,
      from_date: this.from_date,
      to_date: this.to_date,
      rate: this.rate,


    }
     
        this.$http.put(`/api/v1/exchange-rate/${this.$route.params.id}`, data).then((res) => {
          //console.log(res);
          this.getExchangeRate()
          this.$swal({
            title: "",
            text: "تمت تعديل  بنجاح",
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
         
        });
      }})})
      },
      
  
      getPacts() {
        this.$http.get("/api/v1/pact").then((res) => {
          //console.log('pact', res.data.data)
          this.rows = res.data.data;
        });
      },
  
      //
    },
  };
  </script> 
  
  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }
  
  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>